export const BASE_URL = "https://admin.mbabinani.com/api";
export const ImageBASE_URL = "https://admin.mbabinani.com/storage/app/";

export const ENDPOINTS = {
  alumniForm: "/alumni_form",
  BusinessSetting: "/business_setings",
  Home: "/home",
  Page: "/page",
  PageContent: "/page_content",
  photo_gallery: "/photo_gallery",
  video_gallery: "/video_gallery",
  our_faculty: "/our_faculty",
  alumniList: "/alumni",
  Enquiry:'/enquiry',
  Faq:'/faqs',
  Clients:'/clients'
};
