import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MyLink from "../component/MyLink";
import { Dimmer, Loader, Image, Segment } from 'semantic-ui-react'
import { ENDPOINTS, BASE_URL, ImageBASE_URL } from '../ApiContext/Config'; // Assuming these are correctly imported

const CompanySlider = () => {
  const [images, setImages] = useState([]); // State to store images

  const settings3 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    rtl: false,
    autoplay: true,
    verticalSwiping: true,
    arrows: false,
    autoplaySpeed: 2000,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  useEffect(() => {
    const fetchImages = async () => {
      const cachedImages = sessionStorage.getItem('clients');

      if (cachedImages) {
        setImages(JSON.parse(cachedImages).data); // Ensure you set `data`
        return;
      }

      try {
        const response = await fetch(`${BASE_URL}${ENDPOINTS.Clients}`);
        const data = await response.json();

        if (data && data.success) {
          sessionStorage.setItem('clients', JSON.stringify(data)); // Cache the entire response
          setImages(data.data); // Only set `data` in the state
        }
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };

    fetchImages();
  }, []);

  return (
    <div className="w-100">
      <Slider {...settings3}>
        {images.length > 0 ? (
          images.map((image, index) => (
            <div key={index} className="swiper-slide ">
              <figure className="swiper-slide-inner m-2">
                <img
                  decoding="async"
                  alt={image.title} // Use image title as alt text
                  src={image.icon ? ImageBASE_URL + image.icon : '/assets/placeholder.jpg'}
                  className="swiper-slide-image text-center"
                />
              </figure>
            </div>
          ))
        ) : (
          <>
            <Segment>
              <Dimmer active>
                <Loader />
              </Dimmer>

              <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
            </Segment>
          </>
        )}
      </Slider>
    </div>
  );
};

export default CompanySlider;
