

import { BASE_URL, ENDPOINTS, ImageBASE_URL } from "../ApiContext/Config";
import { useParams, useLocation } from "react-router-dom";

import { Link } from "react-router-dom";
import VideoGallery3 from "./VIdeoGallary";



import React, { useEffect, useState, useContext } from "react";

import Footer from "../component/Footer";
import { ApiContext } from "../ApiContext/ApiContext";

import Gallery from "./Gallary";
import "../component/CustomCarousel.css";
import Header from "../component/Header";
import Swal from "sweetalert2";
import PhotoGallery from "./PhotoGallery";

import { Dimmer, Loader, Image, Segment } from 'semantic-ui-react'
import MyLink from "../component/MyLink";

const PhotoVideoCategoryDetail = () => {
    const [loading2, setLoading2] = useState(true);

    const { slug } = useParams();
    const [data, setData] = useState(null);
    const location = useLocation();

    const state =
        location.state || JSON.parse(localStorage.getItem("academyState"));
    const id = state ? state.id : null;
    const title = state ? state.title : null;
    const pageId = state ? state.pageId : null;
    const {
        BusinessSetting,
        Page,
        Home,
        loading,
        error2,
        fetchAllData,

    } = useContext(ApiContext);




    const API_URL1 = `${BASE_URL}/photo_gallery?category_id=${id}`;
    const API_URL2 = `${BASE_URL}/video_gallery?category_id=${id}`;




    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        qualification: "",
        city: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    useEffect(() => {


        fetchData();

        localStorage.removeItem("academyState");
    }, [ API_URL1, API_URL2, slug]);



    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${BASE_URL}${ENDPOINTS.Enquiry}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const data = await response.json();
            Swal.fire({
                title: "Success",
                text: "Form submitted successfully",
                icon: "success",
            });
        } catch (error) {
            Swal.fire({
                title: "Error!",
                text: "Error submitting form",
                icon: "error",
            });
        }
        setFormData({
            name: "",
            email: "",
            phone: "",
            qualification: "",
            city: "",
        });
    };

    const backgroundImageUrl =
        BusinessSetting && BusinessSetting.data
            ? ImageBASE_URL + BusinessSetting.data.image_baner
            : "";

    const fetchData = async () => {
        setLoading2(true);

        setData(null);
        var URL =
            slug == "photo_gallery"
                ? API_URL1

                : API_URL2;

        try {
            const response = await fetch(URL);
            if (response.ok) {
                const result = await response.json();

                if (result.success) {
                    setData(result);
                    fetchAllData();

                } else {
                    setLoading2(true);
                    setData(null);

                }
            } else {
                setData(null);


            }
        } catch (error) {
            setLoading2(true);
            setData(null);


        }
        finally {
            setLoading2(false);
        }
    };


    return (


        <>
            {loading && loading2 ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                        backgroundColor: '#f0f0f0',
                    }}
                >
                    <img
                        src="/assets/favicon.png"
                        alt="Loading"
                        style={{
                            width: '150px',
                            height: '150px',
                        }}
                    />
                </div>
            ) : error2 ? (
                <p>{error2}</p>
            ) : BusinessSetting ? (

                <div className="page-template page-template-page-full page-template-page-full-php page page-id-1041 user-registration-page mega-menu-main-menu elementor-default elementor-kit-5 elementor-page elementor-page-1041">
                    <div
                        data-elementor-type="header"
                        data-elementor-id="8"
                        className="elementor elementor-8 elementor-location-header"
                    >

                        {BusinessSetting ? <Header BusinessSetting={BusinessSetting} Page={Page} /> : null}
                    </div>

                    <div
                        data-elementor-type="section"
                        data-elementor-id="1208"
                        className="elementor elementor-1208"
                    >
                        <section
                            className="elementor-section elementor-top-section elementor-element elementor-element-68d8217d elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                            data-id="68d8217d"
                            data-element_type="section"
                            data-settings='{"background_background":"classic"}'
                            style={{
                                backgroundImage: backgroundImageUrl
                                    ? `url(${backgroundImageUrl})`
                                    : "none",

                                backgroundPosition: "center",
                            }}
                        >
                            <div className="elementor-container elementor-column-gap-default">
                                <div
                                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6d3a34c6"
                                    data-id="6d3a34c6"
                                    data-element_type="column"
                                >
                                    <div className="elementor-widget-wrap"></div>
                                </div>
                            </div>
                        </section>
                        
                    </div>

                    <div
                        id="post-1041"
                        className="content post-1041 page type-page status-publish has-post-thumbnail hentry"
                    >
                        <div className="row inner-page-fullwidth">
                            <div className="col-md-3 hide-mobile">
                                <div
                                    data-elementor-type="section"
                                    data-elementor-id="1138"
                                    className="elementor elementor-1138"
                                >
                                    <section
                                        className="elementor-section elementor-top-section elementor-element elementor-element-62f12bb elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                        data-id="62f12bb"
                                        data-element_type="section"
                                    >
                                        <div className="elementor-container elementor-column-gap-default">
                                            <div
                                                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4ecf5da sidebar-nav"
                                                data-id="4ecf5da"
                                                data-element_type="column"
                                            >
                                                <div className="elementor-widget-wrap elementor-element-populated">
                                                    <div
                                                        className="elementor-element elementor-element-21e7b52 elementor-widget elementor-widget-heading"
                                                        data-id="21e7b52"
                                                        data-element_type="widget"
                                                        data-widget_type="heading.default"
                                                    >
                                                        <div className="elementor-widget-container">
                                                            <div className="elementor-heading-title elementor-size-default">
                                                                Admission
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="elementor-element elementor-element-10a1c4f elementor-nav-menu--dropdown-none elementor-widget elementor-widget-nav-menu"
                                                        data-id="10a1c4f"
                                                        data-element_type="widget"
                                                        data-settings='{"layout":"vertical","submenu_icon":{"value":"&lt;i className=\"fas fa-caret-down\"&gt;&lt;\/i&gt;","library":"fa-solid"}}'
                                                        data-widget_type="nav-menu.default"
                                                    >
                                                        <div className="elementor-widget-container">
                                                            <nav
                                                                migration_allowed="1"
                                                                migrated="0"
                                                                role="navigation"
                                                                className="elementor-nav-menu--main elementor-nav-menu__container elementor-nav-menu--layout-vertical e--pointer-background e--animation-fade"
                                                            >
                                                                <ul
                                                                    id="menu-1-10a1c4f"
                                                                    className="elementor-nav-menu sm-vertical"
                                                                >
                                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5420">
                                                                        <MyLink
                                                                            slug="mbabba-programme"
                                                                            pageId="9"
                                                                            id="13"
                                                                            className="elementor-item"
                                                                        >
                                                                            MBA/BBA Program
                                                                        </MyLink>
                                                                    </li>
                                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5420">
                                                                        <MyLink
                                                                            slug="mbabba-course-structure"
                                                                            pageId="9"
                                                                            id="14"
                                                                            className="elementor-item"
                                                                        >
                                                                            MBA/BBA Course Structure
                                                                        </MyLink>
                                                                    </li>
                                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5420">
                                                                        <MyLink
                                                                            slug="admission-process"
                                                                            pageId="9"
                                                                            id="5"
                                                                            className="elementor-item"
                                                                        >
                                                                            MBA/BBA Admission Process
                                                                        </MyLink>
                                                                    </li>
                                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5420">
                                                                        <MyLink
                                                                            slug="eligibility-fees-details"
                                                                            pageId="9"
                                                                            id="18"
                                                                            className="elementor-item"
                                                                        >
                                                                            Eligibility & Fee Details
                                                                        </MyLink>
                                                                    </li>
                                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5420">
                                                                        <Link
                                                                            to="http://samajkalyan.up.gov.in/"
                                                                            target="_blank"
                                                                            className="elementor-item"
                                                                        >
                                                                            Scholarship Policy
                                                                        </Link>
                                                                    </li>
                                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5420">
                                                                        <MyLink
                                                                            slug="gbams-edge-over-others"
                                                                            pageId="9"
                                                                            id="19"
                                                                            className="elementor-item"
                                                                        >
                                                                            Prospective Advantages
                                                                        </MyLink>
                                                                    </li>

                                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5420">
                                                                        <Link
                                                                            to="https://www.gbamsvidushi.com/"
                                                                            target="_blank"
                                                                            className="elementor-item"
                                                                        >
                                                                            GBAMS Vidushi
                                                                        </Link>
                                                                    </li>
                                                                </ul>
                                                            </nav>
                                                            <div
                                                                className="elementor-menu-toggle"
                                                                role="button"
                                                                tabindex="0"
                                                                aria-label="Menu Toggle"
                                                                aria-expanded="false"
                                                            >
                                                                <i
                                                                    aria-hidden="true"
                                                                    role="presentation"
                                                                    className="elementor-menu-toggle__icon--open eicon-menu-bar"
                                                                ></i>
                                                                <i
                                                                    aria-hidden="true"
                                                                    role="presentation"
                                                                    className="elementor-menu-toggle__icon--close eicon-close"
                                                                ></i>{" "}
                                                                <span className="elementor-screen-only">Menu</span>
                                                            </div>
                                                            <nav
                                                                className="elementor-nav-menu--dropdown elementor-nav-menu__container"
                                                                role="navigation"
                                                                aria-hidden="true"
                                                            >
                                                                <ul
                                                                    id="menu-2-10a1c4f"
                                                                    className="elementor-nav-menu sm-vertical"
                                                                >
                                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5420">
                                                                        <MyLink
                                                                            slug="mbabba-programme"
                                                                            pageId="9"
                                                                            id="13"
                                                                            className="elementor-item"
                                                                        >
                                                                            MBA/BBA Program
                                                                        </MyLink>
                                                                    </li>
                                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5420">
                                                                        <MyLink
                                                                            slug="mbabba-course-structure"
                                                                            pageId="9"
                                                                            id="14"
                                                                            className="elementor-item"
                                                                        >
                                                                            MBA/BBA Course Structure
                                                                        </MyLink>
                                                                    </li>
                                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5420">
                                                                        <MyLink
                                                                            slug="admission-process"
                                                                            pageId="9"
                                                                            id="5"
                                                                            className="elementor-item"
                                                                        >
                                                                            MBA/BBA Admission Process
                                                                        </MyLink>
                                                                    </li>
                                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5420">
                                                                        <MyLink
                                                                            slug="eligibility-fees-details"
                                                                            pageId="9"
                                                                            id="18"
                                                                            className="elementor-item"
                                                                        >
                                                                            Eligibility & Fee Details
                                                                        </MyLink>
                                                                    </li>
                                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5420">
                                                                        <Link
                                                                            to="http://samajkalyan.up.gov.in/"
                                                                            target="_blank"
                                                                            className="elementor-item"
                                                                        >
                                                                            Scholarship Policy
                                                                        </Link>
                                                                    </li>
                                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5420">
                                                                        <MyLink
                                                                            slug="gbams-edge-over-others"
                                                                            pageId="9"
                                                                            id="19"
                                                                            className="elementor-item"
                                                                        >
                                                                            Prospective Advantages
                                                                        </MyLink>
                                                                    </li>

                                                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5420">
                                                                        <Link
                                                                            to="https://www.gbamsvidushi.com/"
                                                                            target="_blank"
                                                                            className="elementor-item"
                                                                        >
                                                                            GBAMS Vidushi
                                                                        </Link>
                                                                    </li>
                                                                </ul>
                                                            </nav>
                                                        </div>
                                                    </div>
                                                    <section
                                                        className="elementor-section elementor-inner-section elementor-element elementor-element-ff3a0b9 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                                        data-id="ff3a0b9"
                                                        data-element_type="section"
                                                        data-settings='{"background_background":"classic"}'
                                                    >
                                                        <div className="elementor-container elementor-column-gap-default">
                                                            <div
                                                                className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-a69ca49"
                                                                data-id="a69ca49"
                                                                data-element_type="column"
                                                            >
                                                                <div className="elementor-widget-wrap elementor-element-populated">
                                                                    <div
                                                                        className="elementor-element elementor-element-1e1c548 elementor-widget elementor-widget-heading"
                                                                        data-id="1e1c548"
                                                                        data-element_type="widget"
                                                                        data-widget_type="heading.default"
                                                                    >
                                                                        <div className="elementor-widget-container">
                                                                            <div className="elementor-heading-title elementor-size-default">
                                                                                Contact Us
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="elementor-element elementor-element-343ebb1 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                                                                        data-id="343ebb1"
                                                                        data-element_type="widget"
                                                                        data-widget_type="icon-list.default"
                                                                    >
                                                                        <div className="elementor-widget-container">
                                                                            <ul className="elementor-icon-list-items">
                                                                                <li className="elementor-icon-list-item">
                                                                                    <span className="elementor-icon-list-icon">
                                                                                        <i
                                                                                            aria-hidden="true"
                                                                                            className="far fa-envelope"
                                                                                        ></i>{" "}
                                                                                    </span>
                                                                                    <span className="elementor-icon-list-text">
                                                                                        {BusinessSetting && BusinessSetting.data
                                                                                            ? BusinessSetting.data.email_id1
                                                                                            : ""}
                                                                                    </span>
                                                                                </li>
                                                                                <li className="elementor-icon-list-item">
                                                                                    <span className="elementor-icon-list-icon">
                                                                                        <i
                                                                                            aria-hidden="true"
                                                                                            className="fas fa-phone-alt"
                                                                                        ></i>{" "}
                                                                                    </span>
                                                                                    <span className="elementor-icon-list-text">
                                                                                        Office Tel:{" "}
                                                                                        {BusinessSetting && BusinessSetting.data
                                                                                            ? BusinessSetting.data.phone_no1
                                                                                            : ""}
                                                                                        <br />
                                                                                        {BusinessSetting && BusinessSetting.data
                                                                                            ? BusinessSetting.data.phone_no2
                                                                                            : ""}
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                    <section
                                                        className="elementor-section elementor-inner-section elementor-element elementor-element-8dbc4f4 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                                        data-id="8dbc4f4"
                                                        data-element_type="section"
                                                        data-settings='{"background_background":"classic","shape_divider_top":"triangle"}'
                                                    >
                                                        <div
                                                            className="elementor-shape elementor-shape-top"
                                                            data-negative="false"
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 1000 100"
                                                                preserveAspectRatio="none"
                                                            >
                                                                <path
                                                                    className="elementor-shape-fill"
                                                                    d="M500,98.9L0,6.1V0h1000v6.1L500,98.9z"
                                                                />
                                                            </svg>
                                                        </div>
                                                        <div className="elementor-container elementor-column-gap-default">
                                                            <div
                                                                className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-db45aec"
                                                                data-id="db45aec"
                                                                data-element_type="column"
                                                            >
                                                                <div className="elementor-widget-wrap elementor-element-populated">
                                                                    <div
                                                                        className="elementor-element elementor-element-10563c8 elementor-widget elementor-widget-text-editor"
                                                                        data-id="10563c8"
                                                                        data-element_type="widget"
                                                                        data-widget_type="text-editor.default"
                                                                    >
                                                                        <div className="elementor-widget-container text-dark">
                                                                            <p className="text-center text-dark">
                                                                                Admissions Enquiry&nbsp;
                                                                                <br />
                                                                                
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="elementor-element elementor-element-a0a1db0 elementor-widget elementor-widget-shortcode"
                                                                        data-id="a0a1db0"
                                                                        data-element_type="widget"
                                                                        data-widget_type="shortcode.default"
                                                                    >
                                                                        <div className="elementor-widget-container">
                                                                            <div className="elementor-shortcode">
                                                                                <script type="text/javascript"></script>
                                                                                <div
                                                                                    className="gf_browser_unknown gform_wrapper gravity-theme gform-theme--no-framework"
                                                                                    data-form-theme="gravity-theme"
                                                                                    data-form-index="0"
                                                                                    id="gform_wrapper_5"
                                                                                >
                                                                                    <div
                                                                                        id="gf_5"
                                                                                        className="gform_anchor"
                                                                                        tabindex="-1"
                                                                                    ></div>

                                                                                    <form
                                                                                        method="post"
                                                                                        encType="multipart/form-data"
                                                                                        onSubmit={handleSubmit}
                                                                                    >
                                                                                        <div className="gform-body gform_body">
                                                                                            <div
                                                                                                id="gform_fields_5"
                                                                                                className="gform_fields top_label form_sublabel_below description_below"
                                                                                            >
                                                                                                <div
                                                                                                    id="field_5_1"
                                                                                                    className="gfield gfield--type-text gfield_contains_required field_sublabel_below gfield--no-description field_description_below hidden_label gfield_visibility_visible"
                                                                                                    data-js-reload="field_5_1"
                                                                                                >
                                                                                                    <label
                                                                                                        className="gfield_label gform-field-label"
                                                                                                        htmlFor="input_5_1"
                                                                                                    >
                                                                                                        Name
                                                                                                        <span className="gfield_required">
                                                                                                            <span className="gfield_required gfield_required_text">
                                                                                                                (Required)
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </label>
                                                                                                    <div className="ginput_container ginput_container_text">
                                                                                                        <input
                                                                                                            className="large"
                                                                                                            name="name"
                                                                                                            type="text"
                                                                                                            value={formData.name}
                                                                                                            onChange={handleChange}
                                                                                                            placeholder="Full Name"
                                                                                                            required
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    id="field_5_2"
                                                                                                    className="gfield gfield--type-email gfield--width-full gfield_contains_required field_sublabel_below gfield--no-description field_description_below hidden_label gfield_visibility_visible"
                                                                                                    data-js-reload="field_5_2"
                                                                                                >
                                                                                                    <label
                                                                                                        className="gfield_label gform-field-label"
                                                                                                        htmlFor="input_5_2"
                                                                                                    >
                                                                                                        Email Address
                                                                                                        <span className="gfield_required">
                                                                                                            <span className="gfield_required gfield_required_text">
                                                                                                                (Required)
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </label>
                                                                                                    <div className="ginput_container ginput_container_email">
                                                                                                        <input
                                                                                                            className="large"
                                                                                                            name="email"
                                                                                                            type="email"
                                                                                                            value={formData.email}
                                                                                                            onChange={handleChange}
                                                                                                            placeholder="Email Address"
                                                                                                            required
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    id="field_5_5"
                                                                                                    className="gfield gfield--type-phone gfield--width-full gfield_contains_required field_sublabel_below gfield--no-description field_description_below hidden_label gfield_visibility_visible"
                                                                                                    data-js-reload="field_5_5"
                                                                                                >
                                                                                                    <label
                                                                                                        className="gfield_label gform-field-label"
                                                                                                        htmlFor="input_5_5"
                                                                                                    >
                                                                                                        Phone Number
                                                                                                        <span className="gfield_required">
                                                                                                            <span className="gfield_required gfield_required_text">
                                                                                                                (Required)
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </label>
                                                                                                    <div className="ginput_container ginput_container_phone">
                                                                                                        <input
                                                                                                            className="large"
                                                                                                            name="phone"
                                                                                                            type="tel"
                                                                                                            value={formData.phone}
                                                                                                            onChange={handleChange}
                                                                                                            placeholder="Phone Number"
                                                                                                            required
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    id="field_5_6"
                                                                                                    className="gfield gfield--type-select gfield--width-full gfield_contains_required field_sublabel_below gfield--no-description field_description_below hidden_label gfield_visibility_visible"
                                                                                                    data-js-reload="field_5_6"
                                                                                                >
                                                                                                    <label
                                                                                                        className="gfield_label gform-field-label"
                                                                                                        htmlFor="input_5_6"
                                                                                                    >
                                                                                                        Your Current Qualification
                                                                                                        <span className="gfield_required">
                                                                                                            <span className="gfield_required gfield_required_text">
                                                                                                                (Required)
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </label>
                                                                                                    <div className="ginput_container ginput_container_select">
                                                                                                        <select
                                                                                                            name="qualification"
                                                                                                            value={formData.qualification}
                                                                                                            onChange={handleChange}
                                                                                                            className="large gfield_select"
                                                                                                            aria-required="true"
                                                                                                            required
                                                                                                        >
                                                                                                            <option
                                                                                                                value
                                                                                                                selected="selected"
                                                                                                                className="gf_placeholder"
                                                                                                            >
                                                                                                                Your Current Qualification
                                                                                                            </option>

                                                                                                            <option value="B.A.">
                                                                                                                B.A.
                                                                                                            </option>
                                                                                                            <option value="B.Sc.">
                                                                                                                B.Sc.
                                                                                                            </option>
                                                                                                            <option value="B.Com">
                                                                                                                B.Com
                                                                                                            </option>
                                                                                                            <option value="BCA">
                                                                                                                BCA
                                                                                                            </option>
                                                                                                            <option value="B.Tech">
                                                                                                                B.Tech
                                                                                                            </option>
                                                                                                            <option value="Commerce">
                                                                                                                Commerce (10+2)
                                                                                                            </option>
                                                                                                            <option value="Science">
                                                                                                                Science (10+2)
                                                                                                            </option>
                                                                                                            <option value="Arts">
                                                                                                                Arts (10+2)
                                                                                                            </option>
                                                                                                            <option value="Any other">
                                                                                                                Any other
                                                                                                            </option>
                                                                                                        </select>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    id="field_5_7"
                                                                                                    className="gfield gfield--type-text gfield--width-full field_sublabel_below gfield--no-description field_description_below hidden_label gfield_visibility_visible"
                                                                                                    data-js-reload="field_5_7"
                                                                                                >
                                                                                                    <label
                                                                                                        className="gfield_label gform-field-label"
                                                                                                        htmlFor="input_5_7"
                                                                                                    >
                                                                                                        City
                                                                                                    </label>
                                                                                                    <div className="ginput_container ginput_container_text">
                                                                                                        <input
                                                                                                            ype="text"
                                                                                                            defaultValue
                                                                                                            className="large"
                                                                                                            name="city"
                                                                                                            type="text"
                                                                                                            value={formData.city}
                                                                                                            onChange={handleChange}
                                                                                                            placeholder="Course"
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    id="field_5_8"
                                                                                                    className="gfield gfield--type-captcha gfield--width-full field_sublabel_below gfield--no-description field_description_below hidden_label gfield_visibility_visible"
                                                                                                    data-js-reload="field_5_8"
                                                                                                >
                                                                                                    <label
                                                                                                        className="gfield_label gform-field-label"
                                                                                                        htmlFor="input_5_8"
                                                                                                    >
                                                                                                        CAPTCHA
                                                                                                    </label>
                                                                                                    <div
                                                                                                        id="input_5_8"
                                                                                                        className="ginput_container ginput_recaptcha"
                                                                                                        data-sitekey="6Le33jYgAAAAAHEf7cxacf3DC9xqhEEqAIWisWwH"
                                                                                                        data-theme="light"
                                                                                                        data-tabindex={-1}
                                                                                                        data-size="invisible"
                                                                                                        data-badge="bottomright"
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="gform_footer top_label">
                                                                                            <input
                                                                                                type="submit"
                                                                                                id="gform_submit_button_5"
                                                                                                className="gform_button button large w-100"
                                                                                                defaultValue="Get a call back!"
                                                                                                value="Get a call back"
                                                                                            />
                                                                                        </div>
                                                                                    </form>
                                                                                </div>
                                                                                <iframe
                                                                                    data-lazyloaded="1"
                                                                                    src="about:blank"
                                                                                    style={{
                                                                                        display: "none",
                                                                                        width: "0px",
                                                                                        height: "0px",
                                                                                    }}
                                                                                    data-src="about:blank"
                                                                                    name="gform_ajax_frame_5"
                                                                                    id="gform_ajax_frame_5"
                                                                                    title="This iframe contains the logic required to handle Ajax powered Gravity Forms."
                                                                                ></iframe>
                                                                                <noscript>
                                                                                    <iframe
                                                                                        style={{
                                                                                            display: "none",
                                                                                            width: "0px",
                                                                                            height: "0px",
                                                                                        }}
                                                                                        src="about:blank"
                                                                                        name="gform_ajax_frame_5"
                                                                                        id="gform_ajax_frame_5"
                                                                                        title="This iframe contains the logic required to handle Ajax powered Gravity Forms."
                                                                                    ></iframe>
                                                                                </noscript>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>

                            {data != null && data.data != null ? (
                                <div className="col-md-9">
                                    <div className="page-title-2">
                                        <h2 className="elementor-heading-title elementor-size-default">
                                            {title}
                                        </h2>
                                        <div className="elementor-divider">
                                            <span className="elementor-divider-separator"> </span>
                                        </div>
                                    </div>

                                    <div
                                        data-elementor-type="wp-page"
                                        data-elementor-id="1041"
                                        className="elementor elementor-1041"
                                    >
                                        <section
                                            className="elementor-section elementor-top-section elementor-element elementor-element-464b4cf elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                            data-id="464b4cf"
                                            data-element_type="section"
                                        >

                               

                                            {slug == "photo_gallery"
                                                ?   <PhotoGallery data={data} slug={slug}/>
                                                : <  VideoGallery3 videos={data}/>

                                            }


                                          



                                        </section>
                                    </div>
                                </div>
                            ) : (
                                <div className="col-md-9">
                                <div className="page-title-2">
                                  <h2 className="elementor-heading-title elementor-size-default">
                                    {slug == "photo-gallery"
                                      ? "Photo Gallary"
                                      : slug == "faculty-staff"
                                        ? "Faculty & Staff"
                                        : slug == "video-gallery"
                                          ? "Video Gallary"
                                          : data != null && data.data != null
                                            ? data.data.title
                                            : ""}
                                  </h2>
                                  <div className="elementor-divider">
                                    <span className="elementor-divider-separator"> </span>
                                  </div>
                                </div>
              
                                <div
                                  data-elementor-type="wp-page"
                                  data-elementor-id="1041"
                                  className="elementor elementor-1041"
                                >
                                  <section
                                    className="elementor-section elementor-top-section elementor-element elementor-element-464b4cf elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                    data-id="464b4cf"
                                    data-element_type="section"
                                  >
              
              
                                    <div className="elementor-container elementor-column-gap-default">
              
                                      <div
                                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ee597d1"
                                        data-id="ee597d1"
                                        data-element_type="column"
                                      >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                          <div className="elementor-widget-container ckeditor-content">
                                            <Segment>
                                              <Dimmer active>
                                                <Loader />
                                              </Dimmer>
              
                                              <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
                                            </Segment>
                                          </div>
                                        </div>
                                      </div>
              
                                    </div>
              
                                  </section>
                                </div>
                              </div>
                            )}
                        </div>
                    </div>

                    <Footer BusinessSetting={BusinessSetting} />
                </div>
            ) : null}</>
    );
};

export default PhotoVideoCategoryDetail;
