import React from 'react';
import { Link } from 'react-router-dom';
import { ImageBASE_URL } from '../ApiContext/Config';
import { useNavigate } from 'react-router-dom';

const MediaClipCard = ({ data }) => {
    const navigate = useNavigate();

    const handleLink = (slug, pageId, id) => {
        const state = {
            pageId: pageId,
            id: id
        };
        navigate(`/academy-detail/${slug}`, { state });
    };
    return (
      <div
        data-elementor-type="wp-page"
        data-elementor-id="1379"
        className="elementor elementor-1379"
      >
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-24d2cbb elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="24d2cbb"
          data-element_type="section"
        >
          <div className="elementor-container elementor-column-gap-default">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-c005170"
              data-id="c005170"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-312276b elementor-posts--thumbnail-top elementor-grid-3 elementor-grid-tablet-2 elementor-grid-mobile-1 elementor-widget elementor-widget-posts"
                  data-id="312276b"
                  data-element_type="widget"
                  data-settings='{"custom_columns":"3","custom_columns_tablet":"2","custom_columns_mobile":"1","custom_row_gap":{"unit":"px","size":35,"sizes":[]},"custom_row_gap_tablet":{"unit":"px","size":"","sizes":[]},"custom_row_gap_mobile":{"unit":"px","size":"","sizes":[]}}'
                  data-widget_type="posts.custom"
                >
                  <div className="elementor-widget-container">
                    <div
                      className="ecs-posts elementor-posts-container elementor-posts   elementor-grid elementor-posts--skin-custom"
                      data-settings='{"current_page":1,"max_num_pages":2,"load_method":"numbers_and_prev_next","widget_id":"312276b","post_id":1379,"theme_id":1379,"change_url":false,"reinit_js":false}'
                    >
                      {data.map((faculty, index) => (
                        <article
                          id="post-2250"
                          className="elementor-post elementor-grid-item ecs-post-loop post-2250 ghsimrfaculty type-ghsimrfaculty status-publish has-post-thumbnail hentry"
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          <div
                            data-elementor-type="loop"
                            data-elementor-id="1375"
                            className="elementor elementor-1375 post-2250 ghsimrfaculty type-ghsimrfaculty status-publish has-post-thumbnail hentry"
                          >
                            <section
                              className="elementor-section elementor-top-section elementor-element elementor-element-dce4701 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                              data-id="dce4701"
                              data-element_type="section"
                            >
                              <div className="elementor-container elementor-column-gap-default">
                                <div
                                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-58dd389"
                                  data-id="58dd389"
                                  data-element_type="column"
                                  data-settings='{"background_background":"classic"}'
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <section
                                      className="elementor-section elementor-inner-section elementor-element elementor-element-8090385 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                      data-id="8090385"
                                      data-element_type="section"
                                    >
                                      <div className="elementor-container elementor-column-gap-default">
                                        <div
                                          className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-8df7a74"
                                          data-id="8df7a74"
                                          data-element_type="column"
                                        >
                                          <div className="elementor-widget-wrap elementor-element-populated">
                                            <div
                                              className="elementor-element elementor-element-817e3ee faculty-img elementor-widget elementor-widget-theme-post-featured-image elementor-widget-image"
                                              data-id="817e3ee"
                                              data-element_type="widget"
                                              data-widget_type="theme-post-featured-image.default"
                                            >
                                              <div
                                                className="elementor-widget-container"
                                                onClick={() =>
                                                  handleLink(
                                                    `${faculty.slug}`,
                                                    `${faculty.id}`,
                                                    "1"
                                                  )
                                                }
                                              >
                                                <img
                                                  decoding="async"
                                                  title={faculty.title}
                                                  alt={faculty.title}
                                                  data-src={
                                                    ImageBASE_URL +
                                                    faculty.image1
                                                  }
                                                  className="lazyload mt-4"
                                                  src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                                />
                                                <noscript>
                                                  <img
                                                    decoding="async"
                                                    src={
                                                      ImageBASE_URL +
                                                      faculty.image1
                                                    }
                                                    title={faculty.title}
                                                    alt={faculty.title}
                                                  />
                                                </noscript>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </section>

                                    <section
                                      className="elementor-section elementor-inner-section elementor-element elementor-element-65c46b6 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                      data-id="65c46b6"
                                      data-element_type="section"
                                    >
                                      <div className="elementor-container elementor-column-gap-default">
                                        <div
                                          className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-f1e6fd1"
                                          data-id="f1e6fd1"
                                          data-element_type="column"
                                        >
                                          <div className="elementor-widget-wrap elementor-element-populated">
                                            <div
                                              className="elementor-element elementor-element-ef915e9 elementor-widget elementor-widget-theme-post-title elementor-page-title elementor-widget-heading text-center"
                                              data-id="ef915e9"
                                              data-element_type="widget"
                                              data-widget_type="theme-post-title.default"
                                            >
                                              <div className="elementor-widget-container">
                                                <div
                                                  className="elementor-heading-title elementor-size-default"
                                                  onClick={() =>
                                                    handleLink(
                                                      `${faculty.slug}`,
                                                      `${faculty.id}`,
                                                      "1"
                                                    )
                                                  }
                                                >
                                                  {faculty.title}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </section>
                                  </div>
                                </div>
                              </div>
                            </section>
                          </div>
                        </article>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
};

export default MediaClipCard;
