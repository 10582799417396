
import React, { useEffect, useState, useRef, } from "react";
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import {

  Form,

} from "reactstrap";
import './PopupForm.css'; // Import the CSS file for styling

const PopupForm = () => {
  const [isOpen, setIsOpen] = useState(false);
  const modalRef = useRef(null);
  const openLoginPopup = () => {
    setIsOpen(true);
    document.body.classList.add('modal-open');
  };

  const closeLoginPopup = () => {
    setIsOpen(false);
    document.body.classList.remove('modal-open');
  };
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    qualification: "",
    city: "",
  });




  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();


    console.log("=======handleSubmit==============");
    try {
      const response = await fetch(`https://admin.mbabinani.com/api/enquiry`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      closeLoginPopup();
      Swal.fire({
        title: 'Success',
        text: 'Form submitted successfully',
        icon: 'success',
        
      })
    
    } catch (error) {
      Swal.fire({
        title: 'Error!',
        text: 'Error submitting form',
        icon: 'error',
        
      })
     
    }
    setFormData({
      name: "",
    email: "",
    phone: "",
    qualification: "",
    city: "",
    });
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeLoginPopup();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div>
      <Link
        to="#"
        onClick={isOpen ? closeLoginPopup : openLoginPopup}
        className="elementor-button-link elementor-button elementor-size-sm"
        role="button"
      >
        <span className="elementor-button-content-wrapper">
          <span className="elementor-button-text">Admissions Enquiry</span>
        </span>
      </Link>

      {isOpen && (
        <div className={`login-popup ${isOpen ? "open" : ""}`}>
          <section
            className="elementor-section elementor-inner-section elementor-element elementor-element-8dbc4f4 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="8dbc4f4"
            data-element_type="section"
            data-settings='{"background_background":"classic","shape_divider_top":"triangle"}'
            ref={modalRef}
          >
            <div
              className="elementor-shape elementor-shape-top"
              data-negative="false"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1000 270"
                preserveAspectRatio="none"
              >
                <path
                  className="elementor-shape-fill"
                  d="M500,248.9L0,6.1V0h1000v6.1L500,248.8z"
                />
              </svg>
            </div>
            <div className="elementor-container elementor-column-gap-default">
              <div
                className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-db45aec"
                data-id="db45aec"
                data-element_type="column"
              >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div
                    className="elementor-element elementor-element-10563c8 elementor-widget elementor-widget-text-editor"
                    data-id="10563c8"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div className="elementor-widget-container">
                      <p className="text-center text-white">
                        Admissions Enquiry
                      </p>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-a0a1db0 elementor-widget elementor-widget-shortcode"
                    data-id="a0a1db0"
                    data-element_type="widget"
                    data-widget_type="shortcode.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="elementor-shortcode">
                        <script type="text/javascript"></script>
                        <div
                          className="gf_browser_unknown gform_wrapper gravity-theme gform-theme--no-framework"
                          data-form-theme="gravity-theme"
                          data-form-index="0"
                          id="gform_wrapper_5"
                        >
                          <div
                            id="gf_5"
                            className="gform_anchor"
                            tabindex="-1"
                          ></div>

                          <form
                            method="post"
                            encType="multipart/form-data"
                            onSubmit={handleSubmit}
                          >
                            <div className="gform-body gform_body">
                              <div
                                id="gform_fields_5"
                                className="gform_fields top_label form_sublabel_below description_below"
                              >
                                <div
                                  id="field_5_1"
                                  className="gfield gfield--type-text gfield_contains_required field_sublabel_below gfield--no-description field_description_below hidden_label gfield_visibility_visible"
                                  data-js-reload="field_5_1"
                                >
                                  <label
                                    className="gfield_label gform-field-label"
                                    htmlFor="input_5_1"
                                  >
                                    Name
                                    <span className="gfield_required">
                                      <span className="gfield_required gfield_required_text">
                                        (Required)
                                      </span>
                                    </span>
                                  </label>
                                  <div className="ginput_container ginput_container_text">
                                    <input
                                      className="large"
                                      type="text"
                                      name="name"
                                      value={formData.name}
                                      onChange={handleChange}
                                      placeholder="Full Name"
                                      required
                                    />
                                  </div>
                                </div>
                                <div
                                  id="field_5_2"
                                  className="gfield gfield--type-email gfield--width-full gfield_contains_required field_sublabel_below gfield--no-description field_description_below hidden_label gfield_visibility_visible"
                                  data-js-reload="field_5_2"
                                >
                                  <label
                                    className="gfield_label gform-field-label"
                                    htmlFor="input_5_2"
                                  >
                                    Email Address
                                    <span className="gfield_required">
                                      <span className="gfield_required gfield_required_text">
                                        (Required)
                                      </span>
                                    </span>
                                  </label>
                                  <div className="ginput_container ginput_container_email">
                                    <input
                                      className="large"
                                      name="email"
                                      type="email"
                                      value={formData.email}
                                      onChange={handleChange}
                                      placeholder="Email Address"
                                      required
                                    />
                                  </div>
                                </div>
                                <div
                                  id="field_5_5"
                                  className="gfield gfield--type-phone gfield--width-full gfield_contains_required field_sublabel_below gfield--no-description field_description_below hidden_label gfield_visibility_visible"
                                  data-js-reload="field_5_5"
                                >
                                  <label
                                    className="gfield_label gform-field-label"
                                    htmlFor="input_5_5"
                                  >
                                    Phone Number
                                    <span className="gfield_required">
                                      <span className="gfield_required gfield_required_text">
                                        (Required)
                                      </span>
                                    </span>
                                  </label>
                                  <div className="ginput_container ginput_container_phone">
                                    <input
                                      className="large"
                                      name="phone"
                                      type="tel"
                                      value={formData.phone}
                                      onChange={handleChange}
                                      placeholder="Phone Number"
                                      required
                                    />
                                  </div>
                                </div>
                                <div
                                  id="field_5_6"
                                  className="gfield gfield--type-select gfield--width-full gfield_contains_required field_sublabel_below gfield--no-description field_description_below hidden_label gfield_visibility_visible"
                                  data-js-reload="field_5_6"
                                >
                                  <label
                                    className="gfield_label gform-field-label"
                                    htmlFor="input_5_6"
                                  >
                                    Your Current Qualification
                                    <span className="gfield_required">
                                      <span className="gfield_required gfield_required_text">
                                        (Required)
                                      </span>
                                    </span>
                                  </label>
                                  <div className="ginput_container ginput_container_select">
                                    <select
                                      name="qualification"
                                      value={formData.qualification}
                                      onChange={handleChange}
                                      className="large gfield_select"
                                      aria-required="true"
                                      required
                                    >
                                      <option
                                        value
                                        selected="selected"
                                        className="gf_placeholder"
                                      >
                                        Your Current Qualification
                                      </option>

                                      <option value="B.A.">B.A.</option>
                                      <option value="B.Sc.">B.Sc.</option>
                                      <option value="B.Com">B.Com</option>
                                      <option value="BCA">BCA</option>
                                      <option value="B.Tech">B.Tech</option>
                                      <option value="Commerce">
                                        Commerce (10+2)
                                      </option>
                                      <option value="Science">
                                        Science (10+2)
                                      </option>
                                      <option value="Arts">Arts (10+2)</option>
                                      <option value="Any other">
                                        Any other
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <div
                                  id="field_5_7"
                                  className="gfield gfield--type-text gfield--width-full field_sublabel_below gfield--no-description field_description_below hidden_label gfield_visibility_visible"
                                  data-js-reload="field_5_7"
                                >
                                  <label
                                    className="gfield_label gform-field-label"
                                    htmlFor="input_5_7"
                                  >
                                    City
                                  </label>
                                  <div className="ginput_container ginput_container_text">
                                    <input
                                      ype="text"
                                      defaultValue
                                      className="large"
                                      name="city"
                                      type="text"
                                      value={formData.city}
                                      onChange={handleChange}
                                      placeholder="Course"
                                    />
                                  </div>
                                </div>
                                <div
                                  id="field_5_8"
                                  className="gfield gfield--type-captcha gfield--width-full field_sublabel_below gfield--no-description field_description_below hidden_label gfield_visibility_visible"
                                  data-js-reload="field_5_8"
                                >
                                  <label
                                    className="gfield_label gform-field-label"
                                    htmlFor="input_5_8"
                                  >
                                    CAPTCHA
                                  </label>
                                  <div
                                    id="input_5_8"
                                    className="ginput_container ginput_recaptcha"
                                    data-sitekey="6Le33jYgAAAAAHEf7cxacf3DC9xqhEEqAIWisWwH"
                                    data-theme="light"
                                    data-tabindex={-1}
                                    data-size="invisible"
                                    data-badge="bottomright"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="gform_footer top_label">
                              <input
                                type="submit"
                                id="gform_submit_button_5"
                                className="gform_button button large w-100"
                                defaultValue="Get a call back!"
                                value="Get a call back"
                              />
                            </div>
                          </form>
                        </div>
                        <iframe
                          data-lazyloaded="1"
                          src="about:blank"
                          style={{
                            display: "none",
                            width: "0px",
                            height: "0px",
                          }}
                          data-src="about:blank"
                          name="gform_ajax_frame_5"
                          id="gform_ajax_frame_5"
                          title="This iframe contains the logic required to handle Ajax powered Gravity Forms."
                        ></iframe>
                        <noscript>
                          <iframe
                            style={{
                              display: "none",
                              width: "0px",
                              height: "0px",
                            }}
                            src="about:blank"
                            name="gform_ajax_frame_5"
                            id="gform_ajax_frame_5"
                            title="This iframe contains the logic required to handle Ajax powered Gravity Forms."
                          ></iframe>
                        </noscript>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </div>
  );
};

export default PopupForm;
